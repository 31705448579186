import FormDefault from "./FormDefault";
import { useEffect, useRef, useState, useCallback } from "react";
import { FormTextInput, NewFormTextInput } from "../../inputs/FormTextInput/FormTextInput";
import { Button } from "../../controls/Button/Button";
import { Link, OnestBoldBig, OnestNormalSmall } from "../../styled/TextComponents";
import CheckBoxMain from "../../controls/check-boxes/CheckBoxMain";
import { ButtonDefault } from "../../controls/Button/ButtonDefault";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { color_red_default, color_white, color_grey_dark, color_transparent } from "../../../constants/colors";
import axios from "axios";
import Config from "../../../Config";
import { useNavigate } from "react-router-dom";
import { FormPhoneInput } from "../../inputs/FormTextInput/FormPhoneInput";
import sha256 from "crypto-js/sha256";



export const FormCheckBox = ({ onChange, value }) => {

    const handleChange = useCallback((e) => {
        onChange(e);
        console.log("checkbox", e.target.checked)
    }, [onChange]);

    return (

        <div>
            <label htmlFor="test" style={{
                boxShadow: "1px 0 4px 0 rgba(178, 178, 178, 0.29)",
                borderRadius: 5,
                width: 25,
                height: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {value ?
                    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 0.75L3.625 7.625L0.5 4.5" stroke="#E12F2F" strokeLinecap="round"
                            strokeLinejoin="round" />
                    </svg>
                    : null
                }
            </label>
            <input style={{
                position: "absolute",
                zIndex: -1,
                opacity: 0,
            }}
                id="test" type="checkbox"
                checked={value}
                onChange={handleChange}

            />
        </div>
    )
}


const RegisterForm = () => {
    const formRef = useRef(null);
    const navigate = useNavigate();
    const phonePattern = "(\\s*)?(\\+)?([- _():=+]?\\d[- _():=+]?){10,14}(\\s*)?";

    const [isLoading, setIsLoading] = useState(false);
    const [isNeedUpdate, setIsNeedUpdate] = useState(false);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    //const [timeZone, setTimeZone] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
    const [isAgree, setIsAgree] = useState("");
    const [pinHash, setPinHash] = useState("");
    const [pin, setPin] = useState("");
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
    const [isEmailMatch, setIsEmailMatch] = useState(false);

    const handleNameChange = useCallback((e) => {
        setName(e.target.value);
    }, [name]);

    const handlePhoneChange = useCallback((e) => {
        setPhone(e.target.value);
    }, [phone]);


    const handleEmailChange = useCallback((e) => {
        setEmail(e.target.value);
        let newIsMatch = e.target.value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
        console.log("email", newIsMatch)
        setIsEmailMatch(newIsMatch);
    }, [email]);
    /* 
    
        const handleTimeZoneChange = useCallback((e) => {
            setTimeZone(e.target.value);
        }, [timeZone]);
     */
    const handleNewPasswordChange = useCallback((e) => {
        setNewPassword(e.target.value);
    }, [newPassword]);

    const handleNewPasswordRepeatChange = useCallback((e) => {
        setNewPasswordRepeat(e.target.value);
    }, [newPasswordRepeat]);

    const handleCheckChange = useCallback((e) => {
        setIsAgree(e.target.checked);
    }, [isAgree]);

    useEffect(() => {
        setIsNeedUpdate(name !== "" && phone !== "" && email !== "" && newPassword !== "" && newPasswordRepeat !== "" && isAgree === true && isEmailConfirmed === true)
    }, [name, phone, email, newPassword, newPasswordRepeat, isAgree, isEmailConfirmed])



    const loginSubmit = () => {

        let backendAddr = Config.BACKEND_ADDR + "/auth/user" // /api

        if (isAgree === false) {
            alert("Необходимо согласие на обработку персональных данных")
            return
        }

        setIsLoading(true)
        if (newPassword.length < 6 || newPassword !== newPasswordRepeat) {
            alert("Пароли должны совпадать и длинна быть 6 символов или больше.")
            return
        }
        axios.put(backendAddr, {
            email: email,
            full_name: name,
            phone_number: phone,
            password: newPassword,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,

        }, { withCredentials: true })
            .then(response => {
                console.debug("register response", response.data)
                console.debug("register response data", response.data)
                if (response.status === 201) {
                    navigate("/service/personal")
                } else {
                    alert("Непредвиденный ответ от сервера")
                }
            }).catch(err => {
                if (err.status === 409) {
                    alert("Пользователь с таким email уже зарегистрирован")
                } else {
                    alert("Непредвиденная ошибка при получении данных от сервера")
                    console.error(err)
                }
            })
    }

    const handleConfirmEmail = async () => {
        if (isEmailMatch) {
            const res = await axios.post(Config.BACKEND_ADDR + "/support/confirmation/email", { email: email })
            if (res.status === 200) {
                setPinHash(res.data.pinHash)
                alert("Письмо с подтверждением отправлено на почту")
            }
        }
        else {
            alert("Некорректный email")
        }
    }
    const handleConfirmPin = async (pin) => {
        setPin(pin.target.value)
        if (pin.target.value.length === 6) {
            if (pinHash !== "") {
                if (sha256(pin.target.value).toString() === pinHash) {
                    setIsEmailConfirmed(true);
                    alert("Email успешно подтвержден");
                } else {
                    alert("Неверный PIN-код");
                    setIsEmailConfirmed(false);
                }
            } else {
                alert("Необходимо подтвердить email")
            }
        }
    }

    return (
        <div style={{
            display: "inline-flex",
            flexDirection: "column",
            padding: 30,
            backgroundColor: "#FFFFFF",
            color: "black",
            alignItems: "center",
            boxSizing: "border-box",
            borderRadius: 20,
            gap: 30,
            width: 410
        }}>
            <div style={{ marginRight: "auto", width: "fit-content", fontSize: 32 }}>
                <OnestBoldBig>{"Регистрация"}</OnestBoldBig>
            </div>

            <NewFormTextInput
                placeholder={"Введите Email"}
                type={"email"}
                title={"E-mail"}
                value={email}
                onChange={handleEmailChange}
                disabled={isEmailConfirmed}
            />
            {!pinHash ?
                <Button
                    outline={false}
                    type="submit"
                    width="80%"
                    height={60}
                    backgroundColor={isEmailMatch ? color_red_default : color_grey_dark}
                    color={color_white}
                    borderColor={color_transparent}
                    fontSize={18}
                    disabled={!isEmailMatch}
                    onClick={handleConfirmEmail}
                >Подтвердить почту</Button>

                :
                <NewFormTextInput
                    placeholder={"000000"}
                    type={"text"}
                    title={"Введите PIN-код"}
                    value={pin}
                    onChange={handleConfirmPin}
                    disabled={isEmailConfirmed}
                    pattern={/^[0-9]{6}$/}
                />
            }
            {isEmailConfirmed
                ?
                <OnestNormalSmall style={{ color: "green" }}>Email подтвержден</OnestNormalSmall>
                :
                null
            }
            <NewFormTextInput
                placeholder={"Введите имя и фамилию"}
                type={"text"}
                title={"Имя и фамилия"}
                value={name}
                onChange={handleNameChange}
            />
            <FormPhoneInput
                type={"tel"}
                title={"Номер телефона"}
                value={phone}
                onChange={handlePhoneChange}
            />
           
            <NewFormTextInput
                placeholder={"Пароль"}
                type={"password"}
                title={"Введите пароль"}
                value={newPassword}
                onChange={handleNewPasswordChange}
            />
            <NewFormTextInput
                placeholder={"Повторите пароль"}
                type={"password"}
                title={"Повторите пароль"}
                value={newPasswordRepeat}
                onChange={handleNewPasswordRepeatChange}
            />
            <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: 5,
                justifyContent: "flex-end",
                marginTop: -20
            }}>
                <FormCheckBox name={"acceptForRules"} value={isAgree} onChange={handleCheckChange} />
                <OnestNormalSmall>
                    Я даю согласие
                    <Link href={"/"}>на обработку персональных данных</Link>
                </OnestNormalSmall>
            </div>
            <Button
                outline={false}
                type="submit"
                width="80%"
                height={60}
                backgroundColor={isNeedUpdate ? color_red_default : color_grey_dark}
                color={color_white}
                borderColor={color_transparent}
                fontSize={18}
                disabled={!isNeedUpdate}
                onClick={loginSubmit}
            >Зарегистрироваться</Button>


        </div>
    )
}

export default RegisterForm