import PersonalDefaultPage from "./PersonalDefaultPage";
import CalendarImage from "../../images/personalPage/calendar.svg";
import CashImage from "../../images/personalPage/money.svg";
import ClockImage from "../../images/personalPage/clock.svg";

import { BsX } from "react-icons/bs";
import { color_grey_dark, color_grey_light, color_grey_ultra_light, color_red_default } from "../../constants/colors";
import { OnestNormalDefault, OnestNormalSmall } from "../../components/styled/TextComponents";

import { Calendar } from "../../components/Calendar";
import { WeeklyTask, WeeklyTasksList } from "../../components/blocks/weeklyTasks/weeklyTasksList/WeeklyTasksList";
import { LessonInfo, UpcomingLessonsBlock } from "../../components/Tables/LessonsTable";
import { ImageBlock } from "../../components/blocks/ImageBlock";
import { IconInfoBlock } from "../../components/blocks/IconInfoBlock";
import React, { useEffect, useState } from "react";
import { ProfileUpdatingModal } from "../../components/blocks/profileUpdating/profileUpdatingModal/ProfileUpdatingModal";
import { useDispatch, useSelector } from "react-redux";
import { get_user } from "../../components/blocks/profileUpdating/dataUpdating/ProfileDataUpdatingBlock";
import { InfinitySpin } from "react-loader-spinner";
import { setUser, updateUser } from "../../store/UserSlice";
import axios from "axios";
import Config from "../../Config";
import Cookies from "js-cookie";
import { LogOut } from "../../components/redirects/LogOut";
import { LoadingContainer } from "../../components/containers/LoadingContainer";
import { ModalDefault } from "../../components/containers/ModalDefault";
import ModalPrompt from "../../components/blocks/ModalPrompt";


export async function getMyId() {
    let id = Cookies.get('user_id');
    if (id === null || id === undefined) {
        LogOut()
    }
    return id
}
export async function getWeeklyTasksList() {
    let response = await axios.get(Config.BACKEND_ADDR + `/tasks/user_tasks/${await getMyId()}`)
    let userTasksData = response.data
    console.log("userTasksData", userTasksData);
    return userTasksData.map(el => new WeeklyTask(el.id, el.task_text),)
}


const PersonalPage = () => {
    const [profileUpdatingState, setProfileUpdatingState] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [modalOpen, setModalOpen] = useState(false);
    const [changeData, setChangeData] = useState({
        changeIcon: CalendarImage,
        changeIconColor: "#8DB3FF",
        changeText: "Проведено занятий",
        changeValue: 0,
        changeType: "lesson_cost",
        patchUrl: "/auth/update_user",
    })

    const dispatch = useDispatch();
    const userData = useSelector(state => state.user);


    useEffect(() => {
        get_user().then(data => {
            setIsLoading(false)
            console.log("userData: ", data)
            dispatch(setUser(data))
        });
    }, [])

    const handleSaveModalPrompt = () => {
             
            let data = {};
            data[changeData.changeType] = +changeData.changeValue;
    
            axios            
                .patch(Config.BACKEND_ADDR + changeData.patchUrl, data)
                .catch((err) => {
                    console.log(err);
                    alert("Ошибка обновления данных ");
                })
                .then(() => {
                    
                    // TODO: убрать реплейс, разные названия на бэкенде и на фронте
                    //data[changeData.changeType.replace("lesson_cost", "lessonPrice")] = changeData.changeValue;
                    dispatch(updateUser(data));
                    setModalOpen(false);
                });
        };
    

    return (
        <>
            <PersonalDefaultPage>
                <LoadingContainer isLoading={isLoading}>
                    <ModalPrompt
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        changeData={changeData} 
                        setChangeData={setChangeData}
                        student={{}}
                        user={userData}
                        onSave={handleSaveModalPrompt}
                        patchUrl={"/auth/update_user"}
                    />

                    <ProfileUpdatingModal 
                        isOpen={profileUpdatingState} 
                        onRequestClose={() => setProfileUpdatingState(false)}
                    />
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        justifyContent: "space-between",
                        width: 730
                    }}>
                        <ImageBlock
                            profileName={userData.name}
                            imageLink={userData.image}
                            changeButtonHandler={() => setProfileUpdatingState(true)}
                        />
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 20,
                            width: "100%"
                        }}>
                            <IconInfoBlock
                                imageLink={CalendarImage}
                                title={`${userData.total_lessons} уроков`}
                                description={"Проведено"}
                                onClick={() => {
                                    setChangeData(Object.assign(changeData, {
                                        changeIcon: CalendarImage,
                                        changeIconColor: "#8DB3FF",
                                        changeText: "Проведено занятий",
                                        changeValue: userData.total_lessons,
                                        changeType: "total_lessons",
                                        patchUrl: "/auth/teacher/totalLessons",
                                    }))

                                    setModalOpen(true)
                                }}
                            >

                            </IconInfoBlock>
                            <IconInfoBlock
                                imageLink={CashImage}
                                title={`${userData.balance}$`}
                                description={"Мой баланс"}
                                onClick={() => {
                                    setChangeData(Object.assign(changeData, {
                                        changeIcon: CashImage,
                                        changeIconColor: "#8DB3FF",
                                        changeText: "Баланс",
                                        changeValue: userData.balance,
                                        changeType: "balance",
                                        patchUrl: "/auth/teacher/balance",
                                    }))

                                    setModalOpen(true)
                                }}
                                >

                            </IconInfoBlock>
                            {
                                userData.nextLessonIn > -1 ?
                                    <IconInfoBlock imageLink={ClockImage} title={`${userData.nextLessonIn > 60 ? parseInt(userData.nextLessonIn / 60) : userData.nextLessonIn} ${userData.nextLessonIn > 60 ? "ч" : "мин"}.`} description={"До урока"}></IconInfoBlock>
                                    :
                                    <IconInfoBlock imageLink={ClockImage} title={`Нет следующего урока`} description={""}></IconInfoBlock>
                            }

                        </div>
                        <UpcomingLessonsBlock lessonsList={userData.lessonsList || []} />
                    </div>
                    <div style={{ width: "fit-content", display: "flex", flexDirection: "column", gap: 20 }}>
                        <WeeklyTasksList />
                        <Calendar />
                    </div>
                </LoadingContainer>
            </PersonalDefaultPage>
        </>
    )
}


export default PersonalPage